declare global {
  interface Window {
    ENVIRONMENT?: string;
  }
}

const w = typeof window !== "undefined" ? window : undefined;

export const rule = (url: URL) => {
  return {
    isDev: isDev(url),
    isProd: isProd(url),
    isB2B: isB2B(url),
    isB2C: isB2C(url),
    isPublic: isPublic(url),
    isPrivate: isPrivate(url),
    pathEndsWith: (segments: string[]) => endsWith(segments, url),
    pathStartsWith: (segments: string[]) => startsWith(segments, url),
    pathIncludes: (segments: string[]) => includes(segments, url),
    pathMatches: (regexps: RegExp[]) => matches(regexps, url),
    pathMatchesExact: (strings: string[]) => matchesExact(strings, url),
  };
};

const isDev = (url: URL): boolean => {
  return (
    (url.host !== "www.telia.se" && url.host !== "telia.se") ||
    (typeof w?.ENVIRONMENT !== "undefined" && w?.ENVIRONMENT === "legacy-dev")
  );
};
const isProd = (url: URL) => url.hostname === "www.telia.se" || url.hostname === "telia.se";

const isB2B = (url: URL) => url.pathname?.startsWith("/foretag");
const isB2C = (url: URL) => !isB2B(url);

const isPublic = (url: URL) =>
  !startsWith(["/privat/mitt-telia", "/mitt-telia", "/foretag/mybusiness"], url);
const isPrivate = (url: URL) => !isPublic(url);

const startsWith = (segment: string[], url: URL) =>
  segment.some((pathname) => url.pathname.startsWith(pathname));
const endsWith = (segment: string[], url: URL) =>
  segment.some((pathname) => url.pathname.endsWith(pathname));

const includes = (segment: string[], url: URL) =>
  segment.some((pathname) => url.pathname.includes(pathname));

const matches = (regexps: RegExp[], url: URL) =>
  regexps.some((pattern) => pattern.test(url.pathname));

const matchesExact = (strings: string[], url: URL) => strings.some((str) => str === url.pathname);
